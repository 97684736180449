import "../css/app.css";

/**
 * Testimonials block
 */
const mainSliderBlock = document.querySelectorAll("#home-slider .slides");
if (mainSliderBlock.length > 0) {
  import("./main-slider").then((module) => {
    mainSliderBlock.forEach((element) => {
      module.mainSliderHandler(element);
    });
  });
}

/**
 * Home animations
 */

if (document.body.classList.contains('home')) {
  import("./home-animations").then((module) => {
    module.homeAnimationsHandler();
  });
}

/**
 * Contact us popup
 */

const togglerButtons = document.querySelectorAll('.contact-toggle');
if (togglerButtons.length > 0) {
  import("./contact-popup").then((module) => {
    module.contactPopupHandler(togglerButtons);
  });
}

/**
 * Videos carousel
 */

const videosCarousel = document.querySelectorAll("#videos_carousel");
if (videosCarousel.length > 0) {
  import("./videos-carousel").then((module) => {
    videosCarousel.forEach((element) => {
      module.videosCarousel(element);
    });
  });
}

/**
 * Opinion carousel
 */

const opinionCarousel = document.querySelectorAll(".opinion-carousel");
if (opinionCarousel.length > 0) {
  import("./opinion-carousel").then((module) => {
    opinionCarousel.forEach((element) => {
      module.opinionCarousel(element);
    });
  });
}

/**
 * Accordions
 */

const accordions = document.querySelectorAll(".accordion");
if (accordions.length > 0) {
  import("./accordions").then((module) => {
    accordions.forEach((element) => {
      module.accordions(element);
    });
  });
}

/**
 * Accordions
 */

const books = document.querySelectorAll(".book");
if (books.length > 0) {
  import("./books").then((module) => {
    books.forEach((element) => {
      module.books(element);
    });
  });
}

/**
 * Header
 */

const header = document.querySelector("#masthead");
import("./header").then((module) => {
  module.handleHeader(header);
});
